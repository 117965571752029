/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        function getYoutubeId(url){
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length === 11) {
              return match[2];
            } else {
              //error
              return false;
            }

        }


        // checks for any menu items that have exactly one child sub-menu and sets the parents link href to the childs
        $('nav .nav > li').each( function() {
            if ($(this).hasClass('menu-item-has-children')) {
                if ($(this).find('ul.sub-menu li').length == 1) {
                    var $child = $(this).find('ul.sub-menu li:first-of-type');

                    $(this).attr('data-modal-popup', 'yes');
                    $(this).attr('data-modal-page-id', $child.attr('data-modal-page-id'));
                    $(this).children('a').attr('href', $child.find('a').attr('href'));
                }
            }
        });



        $('[data-modal-popup="yes"]').on('click', function(e) {
            e.preventDefault();

            $('#your-info').modal('show');

            var pageUrl = $(this).find('a').attr('href') + '?blank=true';

            $('#your-info .page-iframe').attr('src', pageUrl);
        });



        $('.nerdy-gallery-container .item.video').on('click', function() {
            $('#video-embed').modal({
                show: true,
                backdrop: false,
            });

            // var videoUrl = $(this).attr('data-video-url').replace(/^https?:\/\//,'http://');
            var videoUrl = $(this).attr('data-video-url');

            var youtubeUrl = '//www.youtube.com/embed/' + getYoutubeId(videoUrl);

            $('#video-embed .modal-ajax-content').empty().append('<div class="videowrapper"><iframe width="420" height="315" src="' + youtubeUrl + '" frameborder="0" allowfullscreen></iframe></div>');
        });


        if ($('.variations-toggle').length > 0) {
            $('#format option').each( function() {
                if ($(this).val() !== '') {
                    var el = $('.variations-toggle .joint-toggle').append('<label onclick="" class="toggle-btn"><input type="radio" name="group3" class="visuallyhidden" value="' + $(this).val() + '">' + $(this).val() + '</label>');
                }

                if ($(this).is(':selected')) {
                    $('.variations-toggle .joint-toggle').find('label:last-of-type').addClass('success');
                }
            });

            $('.variations-toggle').show();
            $('.variations').hide();

            $(".toggle-btn:not('.noscript') input[type=radio]").addClass("visuallyhidden");
            $(".toggle-btn:not('.noscript') input[type=radio]").change(function() {
                if( $(this).attr("name") ) {
                    $(this).parent().addClass("success").siblings().removeClass("success");
                    $('#format').val($(this).val());

                    $('.variations_form').trigger('check_variations');

                } else {
                    $(this).parent().toggleClass("success");
                }
            });
        }



        // desktop
        if (Modernizr.mq('(min-width: 768px)')) {
            console.log('desktop');
        }
        // mobile
        else {
            console.log('mobile');

            $('#mobile-nav-toggle').show();

            $('.menu-main-menu-container').hide();

            var mobileMenuVisible = false;

            $('#mobile-nav-toggle').on('click', function() {
                if (mobileMenuVisible) {
                    $('.menu-main-menu-container').slideUp();

                    mobileMenuVisible = false;
                }
                else {
                    $('.menu-main-menu-container').slideDown();

                    mobileMenuVisible = true;
                }
            })
        }


        $('.btn-buynow').on('click', function() {
            console.log($(this).siblings('select.format').val());

            return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
